.extension-login-btn {
  color: 'red';
  text-decoration: none;
  font-size: 12px;
}

.login-btns {
  border: none;
  color: white;
  border-radius: 15px;
  width: 150px;
  background-color: black;
  &:hover {
    background-color: red;
    color: white;
    border: none;
  }
  &:active {
    background-color: red;
    color: white;
    border: none;
  }
  &:focus {
    background-color: red;
    color: white;
    border: none;
  }
}

/* unvisited link */
.unlock a:link {
  color: rgb(255, 255, 255);
}

/* visited link */
.unlock a:visited {
  color: white;
}

/* mouse over link */
.unlock a:hover {
  color: red;
}

/* selected link */
.unlock a:active {
  color: red;
}