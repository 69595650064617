.admin-nft-mint-card {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px 20px;

    .admin-nft-mint-card-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;

        .nft-mint-container {
            display: flex;
            flex-wrap: wrap;

            button {
                cursor: pointer;
            }
        }
    }
}