body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("/src/assets/desktopBG.png");
  background-repeat: no-repeat;
  background-attachment: fixed; */
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-bg {
  background-image: linear-gradient(rgba(254, 0, 0, 0.75), rgb(0, 0, 0));
}

@media only screen and (min-width: 600px) {
  .video-overlay {
    height: 25vh;
  }

  .video-spectator {
    height: 60vh;
  }

  .dance-contestants {
    object-fit: cover;
    /* background-color: #111111; */
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@media only screen and (max-width: 600px) {
  .video-overlay {
    height: 17vh;
  }

  .video-spectator {
    height: 23vh;
  }

  .dance-contestants {
    object-fit: cover;
    /* object-position: 100% 0; */
    /* background-color: #111111; */
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.video-competitor {
  object-fit: cover;
  /* object-position: 70% 0; */
  background-color: #111111;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

/* eliminated effects */
.competitor-eliminated {
  display: hidden;
  object-fit: cover;
  /* object-position: 70% 0; */
  background-color: #111111;
  animation: fadeOutAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

a.download:link {
  text-decoration: none;
  color: #0404e8;
  font-weight: bold;
}

a.download:hover {
  text-decoration: underline;
  color: #ffffff;
  font-weight: bold;
}

.color-textarea {
  font: 12px 'Roboto', sans-serif;
  /* width: 100px; */
  border-radius: 5px;
  padding: 10px;
  display: flex;
  width: 60%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 210px;
  padding-bottom: 20px;
}

.input {
  padding: 10px 20px;
  color: rgb(83, 81, 81);
  font-size: 1rem;
  border: 0.5px solid rgb(187, 184, 184);
  font-weight: 500;
  background-color: #ffffff;
  width: 100%;
}

.input:focus {
  border: 0.5px solid rgb(187, 184, 184);
  outline: none;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.2px 10px;
  outline: none;
  border: 0.5px solid rgb(187, 184, 184);
  background: rgb(255, 255, 255);
  transition: 0.3s ease;
}

.logo-container {
  display: flex;
  width: 80%;
  justify-content: center;
  background: rgba(68, 68, 68, 0.75);
}

.logo-container-white {
  display: flex;
  width: 80%;
  justify-content: center;
  background: rgba(255, 240, 240, 0.75);
}

@font-face {
  font-family: 'Normal';
  /*Can be any text*/
  src: local('LCD-N__'), url('./fonts/LCD-N__.TTF') format('truetype');
}

.img-btn {
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* coming soon page */
.coming-soon-circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  font-size: 25px;
  color: #000;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.landing-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 2px;
}

.landing-app-demo-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 5px 0px 5px 0px;
}

.landing-button:hover {
  background-color: #0056ff;
  border-radius: 20px;
  box-shadow: 0px 0px 8px 4px #0056ff;
}

.landing-app-demo-button:hover {
  background-color: #0056ff;
  border-radius: 20px;
  box-shadow: 0px 0px 8px 4px #0056ff;
}

.signup-link {
  color: #ff0000;
}

.signup-link:hover {
  color: #fff;
}
