.admin-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: white;

    .home-page-btn {
        position: absolute;
        top: 25px;
        left: 25px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        img {
            width: 16px;
            cursor: pointer;
        }
    }

    .admin-page-wrap {
        width: 100%;
        text-align: center;
        padding: 60px 25px;

        .issue-token-container {
            margin-top: 20px;

            .issue-token-btn-group {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;

                .issue-token-btn {
                    cursor: pointer;
                }
            }
        }

        .set-roles-container {
            margin-top: 20px;

            .set-roles-btn-group {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;

                .set-roles-btn {
                    cursor: pointer;
                }
            }
        }

        .sale-control-btn-container {
            .sale-control-btn {
                cursor: pointer;
            }
        }

        .manage-collection-container {
            .button-group {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                button {
                    width: 120px;
                    cursor: pointer;
                }
            }
        }
    }
}